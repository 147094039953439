import * as React from 'react'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import PublicIcon from '@mui/icons-material/Public'
import NotificationsIcon from '@mui/icons-material/Notifications'
import SettingsIcon from '@mui/icons-material/Settings'
import ResponsiveAppBar from '../AppBar/appBar.component'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_COLOR } from '../../constants/color.constant'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PeopleIcon from '@mui/icons-material/People'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { useEffect, useState } from 'react'
import DomainIcon from '@mui/icons-material/Domain'
// import DomainAddIcon from '@mui/icons-material/DomainAdd'
import { useMobileBreakPoint } from '../../hooks/mobileBreakPoint'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import EngineeringIcon from '@mui/icons-material/Engineering'
import PriceChangeIcon from '@mui/icons-material/PriceChange'
import PaymentsIcon from '@mui/icons-material/Payments'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus'
import InfoIcon from '@mui/icons-material/Info';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { BarChart } from '@mui/icons-material'

const openedMixin = (theme: Theme, isMobile: boolean): CSSObject => ({
    width: isMobile ? '100%' : 240,
    background: BASE_COLOR.PRIMARY,
    color: BASE_COLOR.WHITE,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
})

const closedMixin = (theme: Theme, isMobile: boolean): CSSObject => ({
    background: BASE_COLOR.PRIMARY,
    color: BASE_COLOR.WHITE,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: isMobile ? 0 : `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: isMobile ? 0 : `calc(${theme.spacing(8)} + 1px)`
    }
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
    isMobile: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open, isMobile }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: isMobile ? '100%' : 240,
        width: isMobile ? 0 : `calc(100% - 240px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}))

interface DrawerProps {
    open: boolean
    isMobile: boolean
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })<DrawerProps>(({ theme, open, isMobile }) => ({
    width: isMobile ? '100%' : 240,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme, isMobile),
        '& .MuiDrawer-paper': openedMixin(theme, isMobile)
    }),
    ...(!open && {
        ...closedMixin(theme, isMobile),
        '& .MuiDrawer-paper': closedMixin(theme, isMobile)
    })
}))

export interface Menu {
    text: string
    path: string
    icon: any
    subMenu?: SubMenu[]
}

export interface SubMenu {
    text: string
    path: string
    icon: any
}

export default function MiniDrawer(props: ChildComponent) {
    const { isMobile } = useMobileBreakPoint()
    const { childComponent } = props
    const [open, setOpen] = useState(isMobile ? false : true)
    const navigate = useNavigate()
    const location = useLocation()
    const currentPath = location.pathname
    const [title, setTitle] = useState('')

    const menuList: Menu[] = [
        // {
        //     text: 'Dashboard',
        //     path: '/',
        //     icon: <DashboardIcon />
        // },
        {
            text: 'Statistiques',
            path: '/statistiques',
            icon: <BarChart />
        },
        {
            text: 'Compteur général',
            path: '/general-counter',
            icon: <Filter9PlusIcon />
        },
        // {
        //     text: 'A propos',
        //     path: '/about',
        //     icon: <InfoIcon />
        // },
         {
            text: 'Messages',
            path: '/message',
            icon: <MailOutlineIcon />
        },
        {
            text: 'Utilisateur',
            path: '',
            icon: <AccountBoxIcon />,
            subMenu: [
                { text: 'Prestataire', path: '/utilisateur/prestataire', icon: <PersonIcon /> },
                // { text: 'Supprimer mon compte', path: '/moncompte/supprimer', icon: <PersonIcon /> },
                {
                    text: 'Créateur',
                    path: '/utilisateur/createur',
                    icon: <PersonAddIcon />
                },
                {
                    text: 'Créateur Multi',
                    path: '/utilisateur/createur-multi-compte',
                    icon: <PersonAddIcon />
                },
                {
                    text: 'Rôles',
                    path: '/utilisateur/roles',
                    icon: <PeopleIcon />
                },
                {
                    text: 'Administrateurs',
                    path: '/utilisateur/admins',
                    icon: <AdminPanelSettingsIcon />
                }
            ]
        },

        {
            text: 'Mission',
            path: '/mission',
            icon: <PublicIcon />
        },
        {
            text: "Blocages d'utilisateur",
            path: '/account-blocking',
            icon: <AdminPanelSettingsIcon />
        },
        {
            text: 'Notifications',
            path: '/notification',
            icon: <NotificationsIcon />
        },
        {
            text: 'Paiements',
            path: '/payments',
            icon: <PaymentsIcon />
        },
        {
            text: 'Paramètres',
            path: '/parametre',
            icon: <SettingsIcon />,
            subMenu: [
                {
                    text: 'Domaines',
                    path: '/parametre/domaine',
                    icon: <DomainIcon />
                },
                {
                    text: 'Postes',
                    path: '/parametre/posts',
                    icon: <EngineeringIcon />
                },
                {
                    text: 'Frais de la plateforme',
                    path: '/parametre/fees',
                    icon: <PriceChangeIcon />
                },
                {
                    text: 'Rappel notification',
                    path: '/parametre/minuteur',
                    icon: <PriceChangeIcon />
                },
                {
                    text: 'Conditions Générales des Ventes',
                    path: '/parametre/cgv',
                    icon: <PriceChangeIcon />
                },
                {
                    text: 'Politique de confidentialité',
                    path: '/parametre/politique',
                    icon: <PriceChangeIcon />
                },
                {
                    text: 'FAQ',
                    path: '/parametre/faq',
                    icon: <PriceChangeIcon />
                }
            ]
        }
    ]
    const otherRoutes = ['/modification-mot-de-passe']

    const initTitle = () => {
        for (const menu of menuList) {
            if (menu.path === currentPath) {
                setTitle(menu.text)
                break
            }
            if (menu.subMenu) {
                const search = menu.subMenu.find((e: SubMenu) => e.path === currentPath)
                if (search) {
                    setTitle(search.text)
                    break
                }
            }
            setTitle('Prestataire')
        }
    }

    useEffect(() => {
        initTitle()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        //definition des titres non inclus dans le menu
        if (otherRoutes.includes(currentPath)) {
            switch (currentPath) {
                case '/modification-mot-de-passe':
                    setTitle('Création mot de passe')
                    break
            }
        }
    }, [currentPath, otherRoutes])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" elevation={0} open={open} isMobile={isMobile}>
                <ResponsiveAppBar handleDrawerOpen={setOpen} open={open} PageTitle={title} />
            </AppBar>
            <Drawer variant="permanent" open={open} isMobile={isMobile} className='h-[100vh]'>
                <DrawerHeader>
                    {/* <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton> */}
                    {isMobile && <MenuOpenIcon onClick={() => setOpen(previousState => (isMobile ? false : previousState))} />}
                    {open && !isMobile && (
                        <Box className="w-full" sx={{ display: 'flex', justifyContent: 'center', py: 5 }}>
                            <img src="imageLogo.jpeg" alt="" style={{ width: '30%' }} />
                        </Box>
                    )}
                </DrawerHeader>
                {/* <Divider /> */}
                <List>
                    {menuList.map((e: Menu, index: number) => (
                        <div key={index}>
                            {!e.subMenu && (
                                <ListItem key={e.text} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                            backgroundColor: ((currentPath === e.path) || ((currentPath === '/') && (e.path === '/statistiques'))) ? '#055751' : 'inherit'
                                        }}
                                        onClick={() => {
                                            if (!e.subMenu) {
                                                setOpen(previousState => (isMobile ? false : previousState))
                                                setTitle(e.text)
                                                navigate(e.path)
                                            }
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                                color: BASE_COLOR.WHITE
                                            }}
                                            title={e.text}
                                        >
                                            {e.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={e.text} sx={{
                                            opacity: open ? 1 : 0,
                                            whiteSpace: 'normal',
                                            overflowWrap: 'break-word',
                                            wordWrap: 'break-word',
                                        }} />
                                    </ListItemButton>
                                </ListItem>
                            )}
                            {e.subMenu && (
                                <Accordion
                                    sx={{
                                        backgroundColor: BASE_COLOR.PRIMARY,
                                        color: BASE_COLOR.WHITE,
                                        border: 'none',
                                        boxShadow: 'none',
                                        Padding: 0
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}a-content`}
                                        id={`panel${index}a-header`}
                                        sx={{ marginTop: 0, height: 10 }}
                                    >
                                        <ListItem disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 0.5,
                                                    backgroundColor:
                                                        e.subMenu.find(sub => sub.path === currentPath) !== undefined
                                                            ? '#055751'
                                                            : 'inherit'
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                        marginLeft: open ? 0 : '50%',
                                                        color: BASE_COLOR.WHITE
                                                    }}
                                                    title={e.text}
                                                >
                                                    {e.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={e.text} sx={{
                                                    opacity: open ? 1 : 0,
                                                    whiteSpace: 'normal',
                                                    overflowWrap: 'break-word',
                                                    wordWrap: 'break-word',
                                                }} />
                                            </ListItemButton>
                                        </ListItem>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ marginTop: 0, padding: 0 }}>
                                        {e.subMenu.length > 0 &&
                                            e.subMenu.map((subItem: SubMenu, i: number) => (
                                                <ListItem
                                                    key={`listItemSubmenu-${subItem.text}-${i}`}
                                                    disablePadding
                                                    sx={{ display: 'block' }}
                                                >
                                                    <ListItemButton
                                                        sx={{
                                                            minHeight: 48,
                                                            justifyContent: open ? 'initial' : 'center',
                                                            backgroundColor: currentPath === subItem.path ? '#055751' : 'inherit'
                                                            // px: 2.5
                                                        }}
                                                        onClick={() => {
                                                            setOpen(previousState => (isMobile ? false : previousState))
                                                            setTitle(subItem.text)
                                                            navigate(subItem.path)
                                                        }}
                                                    >
                                                        {!open && (
                                                            <ListItemIcon
                                                                sx={{
                                                                    minWidth: 0,
                                                                    mr: open ? 3 : 'auto',
                                                                    // justifyContent: open ? 'center' : 'right',
                                                                    marginLeft: open ? 0 : '10%',
                                                                    color: BASE_COLOR.WHITE
                                                                }}
                                                                title={subItem.text}
                                                            >
                                                                {subItem.icon}
                                                            </ListItemIcon>
                                                        )}
                                                        <ListItemText
                                                            primary={subItem.text}
                                                            sx={{
                                                                opacity: open ? 1 : 0,
                                                                paddingLeft: open ? 7 : 0,
                                                                whiteSpace: 'normal',
                                                                overflowWrap: 'break-word',
                                                                wordWrap: 'break-word',
                                                            }}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </div>
                    ))}
                </List>
            </Drawer>
            {/* <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: BASE_COLOR.CONTAINER, minHeight: '100vh' }}> */}
            <div className="overflow-hidden w-[100%]">
                <DrawerHeader />
                {childComponent}
            </div>
            {/* </Box> */}
        </Box>
    )
}

export interface ChildComponent {
    childComponent: React.ReactNode
}
