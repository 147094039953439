import styled from '@emotion/styled'
import {
    Backdrop,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    tableCellClasses,
    TextField,
    Grid
} from '@mui/material'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import { useEffect, useState } from 'react'
import { User } from '../interfaces/utilisateur.interface'
import DeleteIcon from '@mui/icons-material/Delete'
import NewAdminModal from '../../../shared/components/Modale/new-admin-modal.component'
import userServices from '../services/utilisateur.service'
import { toast } from 'react-toastify'
import ModaleValidationComponent from '../../../shared/components/Modale/modale-validation.component'
import EditIcon from '@mui/icons-material/Edit'
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: BASE_COLOR.WHITE,
        border: 0
    },
    '&:last-child td, &:last-child th': {
        border: 0
    },
    [`& .${tableCellClasses.root}`]: {
        borderBottom: 'none'
    }
}))

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: BASE_COLOR.CONTAINER,
        // fontSize: 15,
        fontWeight: 'bold'
        // textTransform: 'uppercase'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15
    }
}))

const UtilisateurAdminComponent: React.FC<any> = () => {
    const [loading, setLoading] = useState(false)
    const [adminUsers, setAdminUsers] = useState<User[]>([])
    const [openAddModal, setOpenAddModal] = useState(false)
    const [refresh, setRefresh] = useState(true)
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
    const [selectedUser, setSelectedUser] = useState<User | null>(null)

    const [toUpdate, setToUpdate] = useState<User['_id']| null>(null)
    const [inputEmail, setInputEmail] = useState('')

    const fetchAllAdmins = () => {
        setLoading(true)
        userServices
            .getAllAdmin()
            .then(res => setAdminUsers(res.data.data))
            .catch(console.log)
            .finally(() => {
                setLoading(false)
                setRefresh(false)
            })
    }

    const deleteOne = (id: User['_id']) => {
        if (adminUsers.length !== 1) {
            userServices
                .deleteAUser(id)
                .then(() => {
                    toast.success('Utilisateur supprimé avec succès')
                    handleRefresh()
                })
                .catch(console.log)
        } else toast.error('Il doit exister au moins un administrateur')
    }

    const handleRefresh = () => setRefresh(true)

    const updateOne = (id: User['_id'] | null) => {
        // if (id) domaineServices.update(id, inputDomaine).then(updateDomaine).catch(console.log)
        if (id) userServices.updateAdminEmail(id, inputEmail).then(updateAdmins).catch(console.log)
    }

    const updateAdmins = () => {
        fetchAllAdmins()
    }

    useEffect(() => {
        if (refresh) fetchAllAdmins()
    }, [refresh])

    const [order, setOrder] = useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = useState<string>('fullName')

    const handleSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        const newOrder = isAsc ? 'desc' : 'asc'
        setOrder(newOrder)
        setOrderBy(property)

        const sorted = [...adminUsers].sort((a, b) => (newOrder === 'asc' ? handleSortBy(a, b, property) : handleSortBy(b, a, property)))

        setAdminUsers(sorted)
    }

    const handleSortBy = (a: User, b: User, property: string) => {
        switch (property) {
            case 'fullName':
                return `${a['lastname']} ${a['firstname']}`.localeCompare(`${b['lastname']} ${b['firstname']}`)
            default:
                return (a[property] ?? '').localeCompare(b[property])
        }
    }

    return (
        <>
            <div className="m-4">
                <Button onClick={() => setOpenAddModal(true)} variant="contained">
                    Ajouter un nouvel utilisateur admin
                </Button>
            </div>
            <TableContainer component="div" sx={{ width: '150vh' }}>
                <Table
                    stickyHeader
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none'
                        }
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableHeader sortDirection={orderBy === 'fullName' ? order : false}>
                                <TableSortLabel
                                    active={true}
                                    direction={orderBy === 'fullName' ? order : 'asc'}
                                    onClick={() => handleSort('fullName')}
                                >
                                    Nom
                                </TableSortLabel>
                            </StyledTableHeader>
                            <StyledTableHeader sortDirection={orderBy === 'email' ? order : false}>
                                <TableSortLabel
                                    active={true}
                                    direction={orderBy === 'email' ? order : 'asc'}
                                    onClick={() => handleSort('email')}
                                >
                                    Email
                                </TableSortLabel>
                            </StyledTableHeader>
                            <StyledTableHeader>Actions</StyledTableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {adminUsers.map(admin => (
                            <StyledTableRow key={0}>
                                {admin._id === toUpdate ? (
                                            <>
                                                <TextField
                                                    value={inputEmail}
                                                    label={"Edition du nouvel e-mail"}
                                                    error={inputEmail === ''}
                                                    id="outlined-start-adornment"
                                                    onChange={(e: any) => setInputEmail(e.target.value)}
                                                    variant="outlined"
                                                    sx={{ m: 1, width: '25ch' }}
                                                    helperText={inputEmail === '' ? 'Ce champ ne peut être vide' : ''}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <TableCell>
                                                    {admin.firstname} {admin.lastname}
                                                </TableCell>
                                                <TableCell>{admin.email}</TableCell>
                                            </>
                                        )}
                                <TableCell>
                                {toUpdate === admin._id ? (
                                            <Grid container alignItems={'center'} gap={1} sx={{ justifyContent: 'end' }}>
                                                <CheckCircleOutline
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        updateOne(admin._id)
                                                        setToUpdate(null)
                                                    }}
                                                />
                                                <CancelOutlined className="cursor-pointer" onClick={() => setToUpdate(null)} />
                                            </Grid>
                                        ) :(
                                    <>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setSelectedUser(admin)
                                            setOpenDeleteConfirmation(true)
                                        }}
                                    >
                                        <DeleteIcon />
                                    </div>
                                    <div>
                                        <EditIcon
                                                    color="primary"
                                                    onClick={() => {
                                                        setToUpdate(admin._id)
                                                        setInputEmail(admin.email)
                                                    }}
                                                    className="cursor-pointer"
                                                />
                                    </div>
                                    </>
                                )}
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <NewAdminModal open={openAddModal} setOpen={setOpenAddModal} refresh={handleRefresh} />
            <ModaleValidationComponent
                openModale={openDeleteConfirmation}
                setOpen={setOpenDeleteConfirmation}
                message={`Voulez-vous vraiment supprimer cet utilisateur ?`}
                handleValidate={() => {
                    if (selectedUser) deleteOne(selectedUser._id)
                }}
            />
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </>
    )
}
export default UtilisateurAdminComponent
